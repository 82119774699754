@import "./variables";

.f-card {
  padding: $spacer;
  border-radius: $borderRadiusBase * 4;
  margin: 0 auto;
  border: 0 !important;
  width: 100%;
  // overflow: hidden;
  .f-card-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    &.jc-left {
      justify-content: left;
    }
    &.jc-center {
      justify-content: center;
    }
    &.jc-right {
      justify-content: right;
    }

    & * {
      margin: 0;
    }
    .dropdown-toggle {
      svg {
        font-size: 1.5rem;
      }
      &:after {
        display: none;
      }
    }
  }
  .f-card-body {
    position: relative;
    z-index: 1;
  }
  &.f-card-primary {
    background: rgba(34, 38, 56, 1);
  }
  &.f-card-secondary {
    background: lighten($cardColor, 10);
  }
  &.f-card-whiteLabeled {
    background-color: #272b30 !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 10px !important;
    border-radius: 10px 30px !important;
  }
}

.f-card-futuristic {
  position: relative;
  padding: 1rem 0;
  margin-bottom: 2rem !important;
  .bg-futuristic {
    img {
      position: absolute;
    }
  }

  &.f-card-futuristic-primary {
    .f-card-body {
      background: $cardColor;
      border-radius: 50px;
      margin: 1.5rem 0;
      padding: 1rem 2.5rem;
      &:before,
      &:after {
        top: 50%;
        transform: translateY(-50%);
        background: #efcd8f;
        width: 1px;
        height: 90%;
        z-index: -1;
        position: absolute;
        content: "";
      }
      &:before {
        left: 0;
      }
      &:after {
        right: 0;
      }
    }
    .bg-futuristic {
      img {
        width: 50%;
        &:nth-child(1) {
          top: 0;
          left: 0;
        }
        &:nth-child(2) {
          top: 0;
          right: 0;
          transform: scaleX(-1);
        }
        &:nth-child(3) {
          left: 0;
          bottom: 0;
          transform: rotate(-180deg) scaleX(-1);
        }
        &:nth-child(4) {
          right: 0;
          bottom: 0;
          transform: rotate(-180deg);
        }
      }
    }
  }
  &.f-card-futuristic-secondary {
    position: relative;
    padding: 1rem;
    &:before,
    &:after {
      width: 50.25%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 0;
    }

    > .f-card-body {
      border-radius: 50px;
      margin: 1.75rem 0;
      padding: 0rem 1rem 1rem;
    }
    &.bg-cover {
      &:before {
        content: "";
        background: url("../assets/img/card-futuristic-secondary.svg") left
          center no-repeat;
        background-size: cover;
        left: 0;
      }
      &:after {
        content: "";
        background: url("../assets/img/card-futuristic-secondary.svg") left
          center no-repeat;
        background-size: cover;
        transform: rotate(-180deg) scaleY(-1);
        right: 0;
      }
    }
    &.bg-contain {
      &:before {
        content: "";
        background: url("../assets/img/card-futuristic-secondary.svg") left
          center no-repeat;
        background-size: contain;
        left: 0;
      }
      &:after {
        content: "";
        background: url("../assets/img/card-futuristic-secondary.svg") left
          center no-repeat;
        background-size: contain;
        transform: rotate(-180deg) scaleY(-1);
        right: 0;
      }
      > .f-card-body {
        &::before {
          content: "";
          position: absolute;
          top: -2.05rem;
          left: 50%;
          transform: translateX(-50%);
          width: 50%;
          height: 2px;
          background: #efcd8f;
        }
        // &::after {
        //   content: "";
        //   position: absolute;
        //   bottom: -2.525rem;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   height: 1px;
        //   width: 45%;
        //   border-radius: 100%;
        //   background: rgb(218, 180, 110);
        //   background: linear-gradient(
        //     90deg,
        //     rgba(218, 180, 110, 1) 11%,
        //     rgba(36, 43, 53, 0) 41%,
        //     rgba(36, 43, 53, 0) 57%,
        //     rgba(218, 180, 110, 1) 90%
        //   );
        // }
        @media screen and (max-width: 767px) {
          &:before {
            width: 30%;
            height: 2px;
          }
          &::after {
            width: 20%;
          }
        }
        @media screen and (max-width: 1480px) {
          &::after {
            display: none;
          }
        }
      }
    }
  }
  // &.f-card-futuristic-secondary {
  //   position: relative;
  //   .f-card-body {
  //     padding: 2rem 2.5rem;
  //   }
  //   .bg-futuristic {
  //     img {
  //       top: 0;
  //       bottom: 0;
  //       width: 50%;
  //       &:nth-child(1) {
  //         left: 0;
  //       }
  //       &:nth-child(2) {
  //         transform: scaleX(-1);
  //         right: 0;
  //       }
  //     }
  //   }
  // }
  &.f-card-futuristic-tartiary {
    position: relative;
    .f-card-body {
      padding: 2rem 2.5rem;
      .card-title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -1rem;
      }
    }
    .bg-futuristic {
      img {
        width: 35%;
        top: 0;
        bottom: 0;
        &:nth-child(1) {
          left: 0;
        }
        &:nth-child(2) {
          transform: scaleX(-1);
          right: 0;
        }
      }
    }
  }
  &.f-card-futuristic-quadratic {
    position: relative;
    padding: 2rem;
    .f-card-body {
      padding: 2rem 1rem;
    }
    .bg-futuristic {
      img {
        width: 40%;
        &:nth-child(1) {
          bottom: 0;
          left: 0;
        }
        &:nth-child(2) {
          top: 0;
          right: 0;
          transform: scale(-1);
        }
      }
    }
  }
}
