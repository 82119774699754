@import "./variables";

.f-btn-wrap {
  display: flex;
  align-items: center;
  .f-btn-info {
    background: #33343b;
    border-radius: $borderRadiusBase 0 0 $borderRadiusBase;
    padding: 0.25rem 1rem;
    min-height: 38px;
    display: flex;
    align-items: center;
    .f-truncate-text {
      line-height: 1;
      font-size: 0.9rem;
      display: flex;
      align-items: center;
    }
  }
}

.f-btn,
.btn {
  border-width: 2px;
  border-width: 1px;
  padding: 0.25rem 1rem;
  border-style: solid;
  border-color: $themePrimaryColor;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 38px;
  border-radius: $borderRadiusBase;
  &:hover {
    background-color: $themeSecondaryColor;
    border-color: $themeSecondaryColor;
    cursor: pointer;
  }
  &:active {
    background-color: $themePrimaryColor;
  }
  &:disabled {
    opacity: 0.75;
    pointer-events: none;
  }
  .f-btn-icon {
    font-size: 1.15rem;
    &.f-prefix {
      margin-right: 0.35rem;
    }
    &.f-postfix {
      margin-left: 0.35rem;
    }
    svg {
      display: inline-flex;
      vertical-align: -3px;
    }
  }
  &.f-btn-primary {
    color: #fff;
    background-color: $themePrimaryColor;
    border-color: #cba461;
  }
  &.f-btn-secondary {
    color: $themePrimaryColor;
    background-color: #ffffff;
    border-color: #ffffff;
  }
  &.f-btn-whiteLabeled {
    color: #000000;
    background-color: #fff;
    border-color: #fff;
  }
  &.f-btn-whiteLabeledPrimary {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
  }

  &.f-btn-outlined {
    background: none !important;
    &.f-btn-primary {
      color: $themePrimaryColor;
    }
    &.f-btn-secondary {
      color: #ffffff;
    }
    &.f-btn-whiteLabeled {
      color: #fff;
    }
    &.f-btn-whiteLabeledPrimary {
      color: #fff;
    }
  }
  &.f-btn-rounded {
    border-radius: 100px !important;
  }
}

.btn-futuristic-wrap {
  display: flex;
  position: relative;
  padding: 0.25rem;
  margin-bottom: 1rem !important;
  float: left;
  .btn-img {
    height: 55px;
    min-height: 55px;
    position: relative;
  }
  &.f-btn-futuristic-primary-wrap {
    .btn-img {
      &.left {
        top: 0.15rem;
      }
    }
    &:before,
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
    }
    &:before {
      background: url(./img/btn-border-futuristic-primary.svg) left center no-repeat;
      background-size: contain;
      bottom: -0.1rem;
      left: -0.1rem;
    }
    &:after {
      background: url(./img/btn-border-futuristic-primary.svg) left center no-repeat;
      background-size: contain;
      top: -0.1rem;
      right: -0.1rem;
      transform: rotate(-180deg);
    }
  }
  &.f-btn-futuristic-secondary-wrap {
    .btn-img {
      &.right {
        top: 0.15rem;
      }
    }
    &:before,
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
    }
    &:before {
      background: url(./img/btn-border-futuristic-secondary.svg) left center no-repeat;
      background-size: contain;
      bottom: 0;
      left: 0;
    }
    &:after {
      background: url(./img/btn-border-futuristic-secondary.svg) left center no-repeat;
      background-size: contain;
      top: 0.15rem;
      right: 0;
      transform: rotate(-180deg);
    }
  }

  .f-btn {
    border: 0;
    padding: 0;
    height: 58px;
    margin-right: -40px;
    margin-left: -40px;
    color: white;
    position: relative;
    z-index: 1;
    .f-btn-title {
      padding: 1rem 0;
      font-size: 1.15rem;
      font-weight: 400;
      font-family: $fontFuturistic;
    }
  }
  .f-btn-futuristic-primary {
    background: rgb(218, 180, 110);
    background: linear-gradient(180deg, rgba(218, 180, 110, 0) 5%, rgba(218, 180, 110, 1) 5%, rgba(198, 151, 73, 1) 95%, rgba(218, 180, 110, 0) 95%);
  }
  .f-btn-futuristic-secondary {
    background: rgb(218, 180, 110);
    background: linear-gradient(180deg, rgba(218, 180, 110, 0) 5%, rgba(62, 63, 71, 1) 5%, rgba(28, 34, 41, 1) 95%, rgba(218, 180, 110, 0) 95%);
  }
}
